import type { ArticleUserDTO, Paper } from '~/typesAuto/apicore/v1'
import type { UserWithClientDto } from '~/typesAuto/apicore/v2'
import type { SSOProvider } from '~/typesManual/sso'

// Remove when endpoints gets fixed dont care about other values
export type OldUser = {
  name?: string
  userId: number
  autologin: string
  loginType: 'IP' | 'newuser' | 'SSO'
  readingList: number[]
  paperList: number[]
  isAdmin: boolean
  title: string
  jobPlace?: string
  hasActiveSubscription: boolean
  hasActiveNonTrialSubscription: boolean
  workEmailConfirmed: number
  papers: number[]
  clientId: number
  error: string
}

export default () => {
  const { apiFetch, apiCoreFetch } = useApiFetch()

  return {
    // NOT TESTED part of NO site LOVEBAKKEN_PAPER_ID ugly hack in notification
    subscribeNewsletter(
      email: string,
      newsletter: string | number,
      consent: boolean
    ) {
      return apiFetch<OldUser>('Subscribe', {
        method: 'POST',
        query: { email, newsletter, consent },
      })
    },
    subscribeFreeNewsletter(email: string, newsletterId: string | number) {
      return apiCoreFetch(`newsletters/free/${newsletterId}/subscribe`, {
        method: 'POST',
        body: { email, ref: 'web' },
      })
    },
    async login({ email, password }: { email: string; password: string }) {
      return await apiFetch<OldUser>('Login', {
        method: 'POST',
        query: { email, password },
      })
    },
    async logout(token: string) {
      await apiFetch<OldUser>('Logout', {
        headers: token ? { token } : undefined,
      })
    },
    resendActivationMail(email: string) {
      return apiFetch('UserConfirmation', { query: { email } })
    },
    async autologin(autologin: string) {
      return await apiFetch<OldUser>('Login', { query: { autologin } })
    },
    async iplogin() {
      return await apiFetch<OldUser>('IPLogin')
    },
    async trialSignup(form: Record<string, unknown>) {
      return await apiFetch<OldUser>('TrialSignup', {
        method: 'POST',
        body: form,
      })
    },
    async saveToReadingList(
      userId: number,
      articleId: number,
      paperId: number
    ) {
      return apiCoreFetch(`users/${userId}/articles/${articleId}`, {
        method: 'POST',
        body: { Id: paperId },
      })
    },
    async removeFromReadingList(userId: number, articleId: number) {
      return apiCoreFetch(`users/${userId}/articles/${articleId}`, {
        method: 'DELETE',
      })
    },
    async getReadingList(userId: number, articleRead?: boolean) {
      return apiCoreFetch<ArticleUserDTO[]>(`users/${userId}/articles`, {
        query: { articleRead: articleRead },
      })
    },
    async getReadingListWithPagination({
      userId,
      articleRead,
      pageSize,
      skip,
    }: {
      userId: number
      articleRead?: boolean
      pageSize?: number
      skip?: number
    }): Promise<{ data: ArticleUserDTO[]; totalPages: number }> {
      return apiCoreFetch<
        Promise<{ data: ArticleUserDTO[]; totalPages: number }>
      >(`/v2/users/${userId}/articles`, {
        query: { articleRead, pageSize, skip },
      })
    },
    async papers(userId: number) {
      return await apiCoreFetch<Paper[]>(`users/${userId}/papers`)
    },
    async paper(paperId: number, userId: number) {
      return await apiCoreFetch<Paper>(`users/${userId}/papers/${paperId}`)
    },
    async createUser({
      workEmail,
      password,
    }: {
      workEmail: string
      password: string
    }) {
      return await apiCoreFetch<UserWithClientDto>('/v2/users', {
        method: 'POST',
        body: { workEmail, password },
      })
    },
    async createUserWithDetails(user: {
      contactPhone: string
      name: string
      password: string
      userTitleId: number
      workEmail: string
      birthyear?: string
      countryId?: number
      educationId?: number
      userBranchId?: number
      zipcode?: string
    }) {
      return await apiCoreFetch<UserWithClientDto>('/v2/users', {
        method: 'POST',
        body: user,
      })
    },
    async updateUser(
      userId: number,
      data: Record<string, string | number | boolean>
    ) {
      await apiCoreFetch(`users/${userId}`, {
        method: 'PATCH',
        body: Object.keys(data).map((path) => ({
          op: 'add',
          path,
          value: data[path],
        })),
      })
    },
    /**
     * @param emailProvider - Only ATS uses HubSpot. In v3 of this endpoint the backend will handle this.
     */
    async sendConfirmationEmail(
      userId: number,
      returnUrl: string,
      emailProvider: 'hubspot' | 'peytz' = 'peytz',
      sendWorkEmail = true,
      sendPrivateEmail = false
    ) {
      if (emailProvider === 'hubspot') {
        return await apiCoreFetch(`/v2/users/${userId}/confirmEmail`, {
          query: {
            returnUrl,
            sendWorkEmail,
            sendPrivateEmail,
          },
        })
      }

      return await apiCoreFetch(`users/${userId}/confirmEmail`, {
        query: {
          returnUrl,
          sendWorkEmail,
          sendPrivateEmail,
        },
      })
    },
    async sendAccessGrantedReceiptEmail(userId: number, paperIds: string) {
      return await apiCoreFetch(
        `/v2/users/${userId}/sendAccessGrantedReceiptEmail/?paperids=${paperIds}`
      )
    },
    async confirmEmail(emailConfirmToken: string) {
      return await apiCoreFetch(`users/confirmEmail`, {
        method: 'PATCH',
        body: [{ op: 'add', path: '/WorkEmailConfirmed', value: '1' }],
        query: { token: emailConfirmToken },
      })
    },
    async isSSOEmail(email: string) {
      try {
        return await apiCoreFetch<SSOProvider>(`/SSO`, {
          query: { email },
        })
      } catch {
        return null
      }
    },
    async ssoLogin() {
      return apiCoreFetch<OldUser>('login')
    },
  }
}
