// PROJECT: API
import Articles from '@/api/articles'
import Arena from '@/api/arena'
import User from '@/api/user'
import UserTitles from '@/api/userTitles'
import Jobs from '~/api/jobs'
import Account from '~/api/account'
import UserBranches from '@/api/userBranches'
import Content from '~/api/content'
import Vaa from '@/api/vaa'
import Cosmos from '~/api/cosmos'
import Client from '@/api/client'
import Subscription from '@/api/subscription'
import Paper from '@/api/paper'
import PaymentSessions from '@/api/paymentSessions'
import PaymentMethods from '@/api/paymentMethods'
import Payments from '@/api/payments'
import Leads from '@/api/leads'
import Houses from '~/api/houses'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      api: {
        user: User(),
        client: Client(),
        paper: Paper(),
        subscription: Subscription(),
        content: Content(),
        vaa: Vaa(),
        cosmos: Cosmos(),
        userTitles: UserTitles(),
        userBranches: UserBranches(),
        leads: Leads(),
        paymentSessions: PaymentSessions(),
        paymentMethods: PaymentMethods(),
        payments: Payments(),
        houses: Houses(),
        articles: Articles(),
        jobs: Jobs(),
        account: Account(),
        arena: Arena(),
      },
    },
  }
})
