<template>
  <div class="flex items-center justify-center mb-5 md:mb-10">
    <img v-if="isMm" src="/MM_Logo.svg" alt="Logo" class="h-9" />
    <img v-else src="/A_Logo.svg" alt="Logo" class="h-9" />

    <span v-if="!isMm" class="list-title-m text-blue ml-2">
      {{ name }}
    </span>
  </div>
</template>
<script setup lang="ts">
const config = useRuntimeConfig()
const { isMm } = useBrand()
defineProps<{
  name?: string
}>()
</script>
