export default defineNuxtRouteMiddleware(async () => {
  const autoLogin = useLegacyAutoLogin()
  if (!autoLogin.token.value) {
    return
  }

  const userStore = useUserStore()
  if (!userStore.isLoggedIn) {
    await userStore
      .loadUserFromAutologin({
        autologin: autoLogin.token.value,
      })
      .catch(async (error) => {
        if (error.response && error.response.status === 401) {
          // clear the cookie if the autologin fails
          await userStore.logout()
        } else {
          throw error
        }
      })
  }
})
