import { FetchError } from 'ofetch'
import type {
  BigConstituency,
  GroupType,
  Municipality,
  Region,
  SmallConstituency,
} from '~/typesManual/vaa_api/areas'
import type {
  Profession,
  Education,
  RandomCandidateAnswer,
  CandidateDuel,
  Candidate,
  CandidateAnswer,
  ApiCandidateAnswer,
  CandidateAnswers,
} from '~/typesManual/vaa_api/candidate'
import type {
  ApiBallot,
  BallotCandidate,
  Election,
  ElectionResult,
  ElectionResults,
} from '~/typesManual/vaa_api/election'
import type {
  ApiMatchResults,
  ApiMatch,
  LineUp,
  Match,
  Matches,
  TopicMatch,
} from '~/typesManual/vaa_api/match'
import type { Party, PartyProfile } from '~/typesManual/vaa_api/party'
import type {
  Answer,
  ApiQuestion,
  Question,
  QuestionAnswers,
  Valgomat,
} from '~/typesManual/vaa_api/valgomat'

export default () => {
  const config = useRuntimeConfig()
  const { vaaApiFetch } = useApiFetch()

  return {
    async getElections() {
      return vaaApiFetch<Election[]>('/v1/GetElections')
    },
    async getValgomats(electionId: number): Promise<Valgomat[]> {
      return (
        await vaaApiFetch<
          {
            ID: number
            Name: string
            GroupSpecificQuestions: 1 | 0
            GroupBy: GroupType | null
            CandidateSpecific: 1 | 0 // Only relevant to the server. Restricts a valgomat to specific candidates.
            IsImportant: 1 | 0
          }[]
        >('/v1/GetValgomats', {
          query: {
            electionId,
            frontpage: config.public.site?.vaaConfig?.frontpage,
          },
        })
      ).map((valgomat) => ({
        id: valgomat.ID,
        name: valgomat.Name,
        groupSpecificQuestions: valgomat.GroupSpecificQuestions === 1,
        groupBy: valgomat.GroupBy ?? undefined,
        allowIsImportant: valgomat.IsImportant === 1,
      }))
    },
    async getMunicipalities() {
      return vaaApiFetch<Municipality[]>('/v1/GetMunicipalities')
    },
    async getRegions() {
      return vaaApiFetch<Region[]>('/v1/GetRegions')
    },
    async getConstituenciesSmall() {
      return vaaApiFetch<SmallConstituency[]>('/v1/GetSmallConstituencies')
    },
    async getConstituenciesBig() {
      return vaaApiFetch<BigConstituency[]>('/v1/GetBigConstituencies')
    },
    async getProfessions(): Promise<Profession[]> {
      return (
        await vaaApiFetch<{ id: number; name: string }[]>('/v1/GetProfessions')
      ).map((profession) => ({
        ID: profession.id,
        Name: profession.name,
      }))
    },
    async getEducations(): Promise<Education[]> {
      return (
        await vaaApiFetch<{ id: number; name: string }[]>('/v1/GetEducations')
      ).map((education) => ({
        ID: education.id,
        Name: education.name,
      }))
    },
    async getParties(electionId: number) {
      try {
        return vaaApiFetch<Party[]>('/v1/GetParties', { query: { electionId } })
      } catch (error) {
        if (error instanceof FetchError && error.response?.status === 404) {
          return [] as Party[]
        }

        throw error
      }
    },
    async getQuestions(
      electionId: number,
      valgomatId: number,
      groupId: number, // Used to replace {{group}} in some question texts
      optionalQuestionLanguage?: boolean,
      extraTopicIds?: number[]
    ): Promise<Question[]> {
      const apiQuestions = await vaaApiFetch<ApiQuestion[]>(
        extraTopicIds ? '/v2/BaseQuestions' : '/v2/GetQuestions',
        {
          query: {
            electionId,
            valgomatId,
            groupId,
            frontpage: config.public.site?.vaaConfig?.frontpage,
            includeTopicIds: extraTopicIds?.join(',') || undefined,
          },
        }
      )
      return apiQuestions.map((question: ApiQuestion): Question => {
        if (optionalQuestionLanguage) {
          question.Title = question.OptionalTitle
          question.Question = question.OptionalQuestion
          question.Info = question.OptionalInfo
          question.ArgumentFor = question.OptionalArgumentFor
          question.ArgumentAgainst = question.OptionalArgumentAgainst
        }

        return {
          id: question.Id,
          topic: question.Title || undefined,
          question: question.Question,
          elaboration: question.Info || undefined,
          argumentFor: question.ArgumentFor || undefined,
          argumentAgainst: question.ArgumentAgainst || undefined,
          questionType: question.ID_CandidateQuestionType,
          answerOptions: question.QuestionListData?.map((answerOption) => ({
            id: parseInt(answerOption.Id),
            name: answerOption.Name,
          })),
          isTopicQuestionType: question.IsTopicQuestionType,
        }
      })
    },
    async getCandidateInfo(candidateId: string | number, electionId: number) {
      // Gets a single candidate but for some reason the API returns an array
      return (
        await vaaApiFetch<Candidate[]>('/v1/GetCandidate', {
          query: {
            candidateId,
            electionId,
          },
        })
      )?.[0]
    },
    async getMatch(
      election: Pick<Election, 'ID' | 'valgomat' | 'Prefix' | 'Rule'>,
      groupId: number,
      questionIds: number[],
      answers: QuestionAnswers,
      isParty?: boolean,
      topCount?: number,
      bottomCount?: number,
      extraTopicIds?: number[],
      candidateId?: number | string
    ): Promise<Matches | undefined> {
      if (election.ID === 5) {
        groupId = 2
      }

      const answerList = {
        Answers: Object.fromEntries(
          questionIds.map((questionId) => [
            questionId,
            answers?.[questionId]?.answer ?? 0,
          ])
        ),
        Importants: Object.fromEntries(
          questionIds.map((questionId) => [
            questionId,
            answers?.[questionId]?.important ? 1 : 0,
          ])
        ),
      }

      try {
        const matches = await vaaApiFetch<ApiMatchResults>('/v2/Match', {
          method: 'POST',
          query: {
            electionId: election.ID,
            valgomatId: election.valgomat?.id,
            groupId,
            isParty,
            topCount,
            bottomCount,
            candidateId,
            frontpage: config.public.site?.vaaConfig?.frontpage,
            includeTopicIds: extraTopicIds?.join(',') || undefined,
            includeAllTopicQuestions: !extraTopicIds,
          },
          body: answerList,
        })

        return {
          top: matches.TopMatches.map((match) =>
            formatMatch(
              match,
              election,
              config.public.site.legacydomain,
              isParty
            )
          ),
          bottom: matches.BottomMatches?.map((match) =>
            formatMatch(
              match,
              election,
              config.public.site.legacydomain,
              isParty
            )
          ),
          analyzedAnswers: matches.AnalyzedAnswers?.map((analyzedAnswer) => ({
            percent: analyzedAnswer.procent,
            questionText: analyzedAnswer.questionText,
            answer:
              randomCaniddateAnswerTextToAnswer[analyzedAnswer.useranswer],
          })),
        }
      } catch (error) {
        if (error instanceof FetchError && error.response?.status === 404) {
          return
        }

        throw error
      }
    },
    async getRandomCandidateAnswers(
      election: Pick<Election, 'ID' | 'Prefix'>,
      groupId?: number,
      optionalQuestionLanguage?: boolean
    ): Promise<RandomCandidateAnswer[]> {
      try {
        return (
          await vaaApiFetch<
            {
              name: string
              picture: string
              urlkey: string
              question: string
              answer:
                | 'Helt uenig'
                | 'Overvejende uenig'
                | 'Overvejende enig'
                | 'Helt enig'
            }[]
          >('/v1/GetRandomCandidateAnswers', {
            query: {
              electionId: election.ID,
              groupId,
              optionalQuestionLanguage,
            },
          })
        ).map((randomCandidateAnswer) => ({
          ...randomCandidateAnswer,
          picture: formatCandidateImageUrl(
            randomCandidateAnswer.picture,
            election.Prefix,
            config.public.site.legacydomain
          ),
          // answer: answerTextToAnswer2(randomCandidateAnswer.answer),
          answer:
            randomCaniddateAnswerTextToAnswer[randomCandidateAnswer.answer],
        }))
      } catch (error) {
        if (error instanceof FetchError && error.response?.status === 404) {
          return [] as RandomCandidateAnswer[]
        }

        throw error
      }
    },
    async getCandidateDuel(
      election: Pick<Election, 'ID' | 'Prefix'>,
      groupId?: number,
      optionalQuestionLanguage?: boolean
    ): Promise<CandidateDuel | undefined> {
      try {
        const randomCandidateAnswers = await vaaApiFetch<
          {
            name: string
            picture: string
            urlkey: string
            questionTitle: string
            question: string
            answer: '1' | '2' | '4' | '5'
            answerAsText: string
            argument: string
          }[]
        >('/v1/GetRandomCandidateAnswers1V1', {
          query: {
            electionId: election.ID,
            groupId,
            optionalQuestionLanguage,
          },
        })

        return {
          question: {
            question: randomCandidateAnswers[0].question,
            topic: randomCandidateAnswers[0].questionTitle,
          },
          candidates: randomCandidateAnswers
            .map((randomCandidateAnswer) => ({
              answer: parseInt(randomCandidateAnswer.answer) as Answer,
              name: randomCandidateAnswer.name,
              image: formatCandidateImageUrl(
                randomCandidateAnswer.picture,
                election.Prefix,
                config.public.site.legacydomain
              ),
              urlkey: randomCandidateAnswer.urlkey,
              argument: randomCandidateAnswer.argument || undefined, // Default to undefined if argument is an empty string
            }))
            .sort((a, b) => a.answer - b.answer),
        }
      } catch (error) {
        if (error instanceof FetchError && error.response?.status === 404) {
          return
        }

        throw error
      }
    },
    async getBallotList(
      election: Election,
      groupId: number,
      filters: Record<string, number | string | boolean>
    ) {
      const rawBallot = await vaaApiFetch<ApiBallot>('/v1/GetBallotList', {
        query: {
          ...filters,
          electionId: election.ID,
          groupId,
        },
      })

      return rawBallot.BallotCandidates.map(
        (candidate): BallotCandidate => ({
          id: parseInt(candidate.candidateId),
          name: candidate.name,
          partyId: parseInt(candidate.partyId),
          urlKey: candidate.urlKey,
          elected: candidate.chosen === '1',
          image: formatCandidateImageUrl(
            candidate.picture,
            election.Prefix,
            config.public.site.legacydomain
          ),
        })
      )
    },

    /**
     * /vaa-api/v1/GetElectionResults?electionId=X&groupId=X&count=X&partyId=X&professionId=X&educationId=X&age=X&gender=X&topPriority=true&candidateName=X
     */
    async getElectionResults(
      electionId: number,
      groupId: number,
      filters: Record<string, number | string | boolean>
    ) {
      try {
        return vaaApiFetch<ElectionResults>('/v1/GetElectionResults', {
          query: {
            ...filters,
            electionId,
            groupId,
          },
        })
      } catch (error) {
        if (error instanceof FetchError && error.response?.status === 404) {
          return [] as ElectionResults
        }

        throw error
      }
    },
    async getCandidateElectionResult(
      electionId: number,
      candidateId: string | number
    ) {
      return (
        await vaaApiFetch<ElectionResult[]>('/v1/GetElectionResult', {
          query: {
            electionId,
            candidateId,
            frontpage: config.public.site?.vaaConfig?.frontpage,
          },
        })
      )?.[0]
    },
    async getPartyProfiles(
      electionId: number,
      valgomatId: number,
      groupId: number
    ) {
      return (
        await vaaApiFetch<
          {
            id: string
            party: string
            code: string
            shortname: string
            logo: ''
          }[]
        >('/v1/GetPartyProfiles', {
          query: {
            electionId,
            valgomatId,
            groupId,
          },
        })
      ).map(
        (partyProfile): PartyProfile => ({
          ID: parseInt(partyProfile.id),
          Name: partyProfile.party,
          Code: partyProfile.code,
          ShortName: partyProfile.shortname || undefined,
        })
      )
    },
    async getCandidateAnswers(
      candidateId: string | number,
      electionId: number,
      valgomatId: number,
      groupId?: number
    ): Promise<CandidateAnswers> {
      const rawCandidateAnswers = await vaaApiFetch<ApiCandidateAnswer[]>(
        '/v1/GetCandidateAnswers',
        {
          query: {
            candidateId,
            electionId,
            valgomatId,
            groupId,
            frontpage: config.public.site?.vaaConfig?.frontpage,
          },
        }
      )

      return Object.fromEntries(
        rawCandidateAnswers
          .map(formatCandidateAnswerAsObjectEntry)
          .filter(([, answer]) => answer.answer > 0)
      )
    },
  }
}

const randomCaniddateAnswerTextToAnswer = {
  'Helt uenig': 1,
  'Overvejende uenig': 2,
  'Overvejende enig': 4,
  'Helt enig': 5,
} as const

function formatMatch(
  match: ApiMatch,
  election: Pick<Election, 'Prefix' | 'Rule'>,
  imageDomain: string,
  isParty = false
): Match {
  const formattedMatch: Match = {
    id: parseInt(match.CandidateBasic.Id),
    name: match.CandidateBasic.Name,
    urlKey: match.CandidateBasic.UrlKey,
    profession: match.CandidateBasic.Profession,
    party: {
      id: parseInt(match.CandidateBasic.PartyId),
      name: match.CandidateBasic.Party,
      code: match.CandidateBasic.PartyCode,
    },
    matchPercent: match.Matchpercent,
    birthdate: match.CandidateBasic.Birthdate
      ? new Date(match.CandidateBasic.Birthdate)
      : undefined,
    image: formatCandidateImageUrl(
      match.CandidateBasic.Picture,
      election.Prefix,
      imageDomain
    ),
    isParty,
  }

  if (match.TopicMatch) {
    formattedMatch.topicMatches = match.TopicMatch?.map(
      (topicMatch): TopicMatch => ({
        id: parseInt(topicMatch.TopicId),
        title: topicMatch.Title,
        matchPercent: topicMatch.MatchPercent,
      })
    ).slice(0, 6)
  }

  if (match.CandidateBasic.LineUps && election.Rule !== 'EP') {
    formattedMatch.lineUps = match.CandidateBasic.LineUps?.map(
      (lineUp): LineUp => ({
        name: lineUp.lineUpName,
        type: lineUp.groupType,
      })
    )
  }

  return formattedMatch
}

function formatCandidateAnswerAsObjectEntry(
  candidateAnswer: ApiCandidateAnswer
): [Question['id'], CandidateAnswer] {
  return [
    candidateAnswer.QuestionID,
    {
      answer: candidateAnswer.Answer as Answer,
      important: Boolean(candidateAnswer.IsImportant),
      elaboration: candidateAnswer.Info || undefined,
    },
  ]
}
