import { default as testnxGb4eh78qMeta } from "/opt/buildhome/repo/pages/test.vue?macro=true";
import { default as legacyMmDEtBc1xSMeta } from "/opt/buildhome/repo/pages/legacy.vue?macro=true";
import { default as clienttestzDPik2cn8JMeta } from "/opt/buildhome/repo/pages/clienttest.vue?macro=true";
import { default as pricesZgUgUL3BAzMeta } from "/opt/buildhome/repo/pages/prices.vue?macro=true";
import { default as pricingQrzbg66BTdMeta } from "/opt/buildhome/repo/pages/pricing.vue?macro=true";
import { default as receiptoQV4NCATD5Meta } from "/opt/buildhome/repo/pages/receipt.vue?macro=true";
import { default as wayfrVQRdCshUUMeta } from "/opt/buildhome/repo/pages/wayf.vue?macro=true";
import { default as cookieinfo4GvOZOzBw9Meta } from "/opt/buildhome/repo/pages/cookieinfo.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as ssoLogin7BvNhLICwSMeta } from "/opt/buildhome/repo/pages/ssoLogin.vue?macro=true";
import { default as sponsoredArticlesZfraShFPfvMeta } from "/opt/buildhome/repo/pages/sponsoredArticles.vue?macro=true";
import { default as sponsoredPoLj2oKcVRMeta } from "/opt/buildhome/repo/pages/sponsored.vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as profilerpPZ78QyoXMeta } from "/opt/buildhome/repo/pages/account/profile.vue?macro=true";
import { default as loginsecurityEakWPXnG3QMeta } from "/opt/buildhome/repo/pages/account/loginsecurity.vue?macro=true";
import { default as savedArticles5hTOP89LjbMeta } from "/opt/buildhome/repo/pages/account/savedArticles.vue?macro=true";
import { default as decisionchainsiLWeDJNn2PMeta } from "/opt/buildhome/repo/pages/account/decisionchains.vue?macro=true";
import { default as newsletterrIWb0i7VynMeta } from "/opt/buildhome/repo/pages/account/newsletter.vue?macro=true";
import { default as subscriptionUkjduZnamXMeta } from "/opt/buildhome/repo/pages/account/subscription.vue?macro=true";
import { default as userCreateOwFxp3dE9mMeta } from "/opt/buildhome/repo/pages/userCreate.vue?macro=true";
import { default as userForgotPasswordhobFhtIjWLMeta } from "/opt/buildhome/repo/pages/userForgotPassword.vue?macro=true";
import { default as copyrightYQ2eg6AhYiMeta } from "/opt/buildhome/repo/pages/copyright.vue?macro=true";
import { default as typographyJkaCdJAZzBMeta } from "/opt/buildhome/repo/pages/typography.vue?macro=true";
import { default as searchW2JMgpGXtYMeta } from "/opt/buildhome/repo/pages/search.vue?macro=true";
import { default as newsletterdypMw9Ct6LMeta } from "/opt/buildhome/repo/pages/newsletter.vue?macro=true";
import { default as jobs1jPXQSk35lMeta } from "/opt/buildhome/repo/pages/jobs.vue?macro=true";
import { default as jobAddtiUyNAGqlMeta } from "/opt/buildhome/repo/pages/jobAd.vue?macro=true";
import { default as jobchangezAt9OBhe16Meta } from "/opt/buildhome/repo/pages/jobchange.vue?macro=true";
import { default as articleFxocv4itaaMeta } from "/opt/buildhome/repo/pages/article.vue?macro=true";
import { default as names0d0Q3d9O1iMeta } from "/opt/buildhome/repo/pages/names.vue?macro=true";
import { default as birthdayszYsQzyh6SpMeta } from "/opt/buildhome/repo/pages/birthdays.vue?macro=true";
import { default as papersp56gG0EDpPMeta } from "/opt/buildhome/repo/pages/papers.vue?macro=true";
import { default as podcastB55YovcdhNMeta } from "/opt/buildhome/repo/pages/podcast.vue?macro=true";
import { default as raadhussvarliC6TidixUMeta } from "/opt/buildhome/repo/pages/raadhussvar.vue?macro=true";
import { default as magazineARYqoZf72sMeta } from "/opt/buildhome/repo/pages/magazine.vue?macro=true";
import { default as subscriptiontUoHEGZXa2Meta } from "/opt/buildhome/repo/pages/subscription.vue?macro=true";
import { default as result3wrA9VUwaMMeta } from "/opt/buildhome/repo/pages/subscription/checkout/result.vue?macro=true";
import { default as checkoutfTmbLfrHkNMeta } from "/opt/buildhome/repo/pages/subscription/checkout.vue?macro=true";
import { default as subjectPuY7vSRJyqMeta } from "/opt/buildhome/repo/pages/subject.vue?macro=true";
import { default as personuCNmZMkXgRMeta } from "/opt/buildhome/repo/pages/person.vue?macro=true";
import { default as writerUu6osQqVPuMeta } from "/opt/buildhome/repo/pages/writer.vue?macro=true";
import { default as news7QrcyjWbxlMeta } from "/opt/buildhome/repo/pages/news.vue?macro=true";
import { default as debateAElMSO0sOwMeta } from "/opt/buildhome/repo/pages/debate.vue?macro=true";
import { default as jobnamesGf7CeW5CYbMeta } from "/opt/buildhome/repo/pages/jobnames.vue?macro=true";
import { default as calendarlist0Ry8YTOD2CMeta } from "/opt/buildhome/repo/pages/calendarlist.vue?macro=true";
import { default as calendaryPechgWogzMeta } from "/opt/buildhome/repo/pages/calendar.vue?macro=true";
import { default as qaListiztY0BgQuEMeta } from "/opt/buildhome/repo/pages/qaList.vue?macro=true";
import { default as qavoqBCPG8TgMeta } from "/opt/buildhome/repo/pages/qa.vue?macro=true";
import { default as rssHyND0BWY79Meta } from "/opt/buildhome/repo/pages/rss.vue?macro=true";
import { default as electionsI8MuY52zE4Meta } from "/opt/buildhome/repo/pages/ct/elections.vue?macro=true";
import { default as electiondbGoRxOuywMeta } from "/opt/buildhome/repo/pages/ct/election.vue?macro=true";
import { default as electionCandidateTestJ4H0x1ETz3Meta } from "/opt/buildhome/repo/pages/ct/electionCandidateTest.vue?macro=true";
import { default as electionCandidateTestResultFPQv8k5puDMeta } from "/opt/buildhome/repo/pages/ct/electionCandidateTestResult.vue?macro=true";
import { default as electionProfilePagetzYloY8CMfMeta } from "/opt/buildhome/repo/pages/ct/electionProfilePage.vue?macro=true";
import { default as electionRobotArticle2JsBTUN2sYMeta } from "/opt/buildhome/repo/pages/ct/electionRobotArticle.vue?macro=true";
import { default as electionBallotdNF71QZYrvMeta } from "/opt/buildhome/repo/pages/ct/electionBallot.vue?macro=true";
import { default as termsjw1825O2ZdMeta } from "/opt/buildhome/repo/pages/terms.vue?macro=true";
import { default as termBISVRXU5NKMeta } from "/opt/buildhome/repo/pages/term.vue?macro=true";
import { default as paperAiSYW6Ic3lMeta } from "/opt/buildhome/repo/pages/paper.vue?macro=true";
import { default as frontpage8MPxVad1vpMeta } from "/opt/buildhome/repo/pages/frontpage.vue?macro=true";
import { default as eventsApOCsWZV3RMeta } from "/opt/buildhome/repo/pages/folkemodet/events.vue?macro=true";
import { default as adsQwlR8fQkfDMeta } from "/opt/buildhome/repo/pages/ads.vue?macro=true";
import { default as coursesxyobE01v38Meta } from "/opt/buildhome/repo/pages/courses.vue?macro=true";
import { default as courseKhR2WkdYWDMeta } from "/opt/buildhome/repo/pages/course.vue?macro=true";
import { default as networksAP7DfIkEVrMeta } from "/opt/buildhome/repo/pages/networks.vue?macro=true";
import { default as networkEvNW0OTPDAMeta } from "/opt/buildhome/repo/pages/network.vue?macro=true";
import { default as yearbook0DFMuQCEESMeta } from "/opt/buildhome/repo/pages/yearbook.vue?macro=true";
import { default as commentsqAT6zNZONNMeta } from "/opt/buildhome/repo/pages/comments.vue?macro=true";
import { default as politicalSpeeches2y3CVb46nLMeta } from "/opt/buildhome/repo/pages/politicalSpeeches.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "test",
    path: "/test",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: {"url":"/quiz.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "clienttest",
    path: "/ct",
    component: () => import("/opt/buildhome/repo/pages/clienttest.vue").then(m => m.default || m)
  },
  {
    name: "priceList",
    path: "/prisliste",
    component: () => import("/opt/buildhome/repo/pages/prices.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/priser",
    component: () => import("/opt/buildhome/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "receipt",
    path: "/kvittering",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/receipt.vue").then(m => m.default || m)
  },
  {
    name: "quizId",
    path: "/quiz/:id",
    meta: {"url":"/quiz.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "regering",
    path: "/regering",
    meta: {"url":"/regering.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "wayflogin",
    path: "/wayflogin",
    component: () => import("/opt/buildhome/repo/pages/wayf.vue").then(m => m.default || m)
  },
  {
    name: "cookieinfo",
    path: "/cookieinfo",
    component: () => import("/opt/buildhome/repo/pages/cookieinfo.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "ssoLogin",
    path: "/sso-login",
    component: () => import("/opt/buildhome/repo/pages/ssoLogin.vue").then(m => m.default || m)
  },
  {
    name: "birthdayGreeting",
    path: "/fodselsdagshilsen/:paramid",
    meta: {"url":"/BirthdayGreeting.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "accessmail",
    path: "/accessmail",
    meta: {"url":"/accessmail.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "afmeld",
    path: "/afmeld",
    meta: {"url":"/afmeld.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "afmeld_paper",
    path: "/:paper/afmeld",
    meta: {"url":"/afmeld.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "sponsored",
    path: "/annonsorinnhold",
    meta: {"url":"/annonsorinnhold"},
    component: () => import("/opt/buildhome/repo/pages/sponsoredArticles.vue").then(m => m.default || m)
  },
  {
    name: "sponsored_paper",
    path: "/:paper/annonsorinnhold",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsoredArticles.vue").then(m => m.default || m)
  },
  {
    name: "sponsored_id",
    path: "/annonsorinnhold/:id",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsored.vue").then(m => m.default || m)
  },
  {
    name: "sponsored_paper_id",
    path: "/:paper/annonsorinnhold/:id",
    meta: {"url":"/sponsoreret"},
    component: () => import("/opt/buildhome/repo/pages/sponsored.vue").then(m => m.default || m)
  },
  {
    name: "magasin_vis",
    path: "/magasin/vis/:id",
    meta: {"url":"/magasin/vis"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "kandidater_ft19",
    path: "/kandidater/ft19",
    meta: {"url":"/kandidater/ft19"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "kandidater_ep19",
    path: "/kandidater/ep19",
    meta: {"url":"/kandidater/ep19"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/konto",
    component: () => import("/opt/buildhome/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account_profile",
    path: "/konto/profil",
    component: () => import("/opt/buildhome/repo/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account_loginsecurity",
    path: "/konto/palogging-sikkerhet",
    component: () => import("/opt/buildhome/repo/pages/account/loginsecurity.vue").then(m => m.default || m)
  },
  {
    name: "saved_articles",
    path: "/konto/lagrede-artikler",
    component: () => import("/opt/buildhome/repo/pages/account/savedArticles.vue").then(m => m.default || m)
  },
  {
    name: "account_decisionchains",
    path: "/konto/beslutningskjeder",
    component: () => import("/opt/buildhome/repo/pages/account/decisionchains.vue").then(m => m.default || m)
  },
  {
    name: "account_newsletter",
    path: "/konto/nyhetsbrev",
    component: () => import("/opt/buildhome/repo/pages/account/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "account_subscription",
    path: "/konto/abonnement",
    meta: {"url":"/konto/abonnement"},
    component: () => import("/opt/buildhome/repo/pages/account/subscription.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: {"url":"/admin"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "nejtak",
    path: "/nejtak",
    meta: {"url":"/nejtak.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "usercreate",
    path: "/usercreate",
    component: () => import("/opt/buildhome/repo/pages/userCreate.vue").then(m => m.default || m)
  },
  {
    name: "userconfirmation",
    path: "/userconfirmation",
    meta: {"url":"/userconfirmation"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "useravatar",
    path: "/useravatar",
    meta: {"url":"/useravatar"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "userforgotpassword",
    path: "/userforgotpassword",
    meta: {"url":"/userforgotpassword"},
    component: () => import("/opt/buildhome/repo/pages/userForgotPassword.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/opt/buildhome/repo/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "licens",
    path: "/licens",
    meta: {"url":"/licens"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "legacy-test",
    path: "/legacy-test",
    meta: {"url":"/xx.aspx"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "typography",
    path: "/typography",
    component: () => import("/opt/buildhome/repo/pages/typography.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/opt/buildhome/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "paper_search",
    path: "/:paper/sok",
    component: () => import("/opt/buildhome/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "searchwords",
    path: "/sok/:words",
    component: () => import("/opt/buildhome/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "paper_searchwords",
    path: "/:paper/sok/:words",
    component: () => import("/opt/buildhome/repo/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/opt/buildhome/repo/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "jobs",
    path: "/karriere",
    component: () => import("/opt/buildhome/repo/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "job",
    path: "/karriere/:id",
    component: () => import("/opt/buildhome/repo/pages/jobAd.vue").then(m => m.default || m)
  },
  {
    name: "jobchange-index",
    path: "/stillingsbytte",
    component: () => import("/opt/buildhome/repo/pages/jobchange.vue").then(m => m.default || m)
  },
  {
    name: "jobchange",
    path: "/stillingsbytte/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "paper_jobchange-index",
    path: "/:paper/stillingsbytte",
    component: () => import("/opt/buildhome/repo/pages/jobchange.vue").then(m => m.default || m)
  },
  {
    name: "paper_jobchange",
    path: "/:paper/stillingsbytte/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "article",
    path: "/artikkel/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "paper_article",
    path: "/:paper/artikkel/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "names",
    path: "/nytt-om-navn/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "paper_names",
    path: "/:paper/nytt-om-navn/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "names-index",
    path: "/nytt-om-navn",
    component: () => import("/opt/buildhome/repo/pages/names.vue").then(m => m.default || m)
  },
  {
    name: "paper_names-index",
    path: "/:paper/nytt-om-navn",
    component: () => import("/opt/buildhome/repo/pages/names.vue").then(m => m.default || m)
  },
  {
    name: "birthdays",
    path: "/fodselsdager",
    component: () => import("/opt/buildhome/repo/pages/birthdays.vue").then(m => m.default || m)
  },
  {
    name: "paper_birthdays-index",
    path: "/:paper/fodselsdager",
    component: () => import("/opt/buildhome/repo/pages/birthdays.vue").then(m => m.default || m),
    children: [
  {
    name: "paper_birthdays",
    path: ":id",
    meta: {"overlay":false},
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "papers",
    path: "/nisjer",
    component: () => import("/opt/buildhome/repo/pages/papers.vue").then(m => m.default || m)
  },
  {
    name: "podcastId",
    path: "/podcast/:podcast",
    component: () => import("/opt/buildhome/repo/pages/podcast.vue").then(m => m.default || m)
  },
  {
    name: "raadhussvar-index",
    path: "/raadhussvar",
    component: () => import("/opt/buildhome/repo/pages/raadhussvar.vue").then(m => m.default || m)
  },
  {
    name: "magazine",
    path: "/magasin",
    component: () => import("/opt/buildhome/repo/pages/magazine.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/abonnement",
    component: () => import("/opt/buildhome/repo/pages/subscription.vue").then(m => m.default || m)
  },
  {
    name: "paper_subscription",
    path: "/:paper/abonnement",
    component: () => import("/opt/buildhome/repo/pages/subscription.vue").then(m => m.default || m)
  },
  {
    name: "subscriptionCheckoutResult",
    path: "/abonnement/betaling/resultat",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/subscription/checkout/result.vue").then(m => m.default || m)
  },
  {
    name: "subscriptionCheckout",
    path: "/abonnement/betaling",
    meta: {"layout":"stripped"},
    component: () => import("/opt/buildhome/repo/pages/subscription/checkout.vue").then(m => m.default || m)
  },
  {
    name: "subject",
    path: "/emne/:subject",
    component: () => import("/opt/buildhome/repo/pages/subject.vue").then(m => m.default || m)
  },
  {
    name: "paper_subject",
    path: "/:paper/emne/:subject",
    component: () => import("/opt/buildhome/repo/pages/subject.vue").then(m => m.default || m)
  },
  {
    name: "person",
    path: "/person/:person",
    component: () => import("/opt/buildhome/repo/pages/person.vue").then(m => m.default || m)
  },
  {
    name: "paper_person",
    path: "/:paper/person/:person",
    component: () => import("/opt/buildhome/repo/pages/person.vue").then(m => m.default || m)
  },
  {
    name: "writer",
    path: "/forfatter/:writer",
    component: () => import("/opt/buildhome/repo/pages/writer.vue").then(m => m.default || m)
  },
  {
    name: "paper_writer",
    path: "/:paper/forfatter/:writer",
    component: () => import("/opt/buildhome/repo/pages/writer.vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/nyheter",
    component: () => import("/opt/buildhome/repo/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "debate",
    path: "/debatt",
    component: () => import("/opt/buildhome/repo/pages/debate.vue").then(m => m.default || m)
  },
  {
    name: "decisionchain",
    path: "/lovforslag",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "decisionchain_id",
    path: "/lovforslag/:id",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "paper_decisionchain",
    path: "/:paper/lovforslag",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "paper_decisionchain_id",
    path: "/:paper/lovforslag/:id",
    meta: {"url":"/decisionchain"},
    component: () => import("/opt/buildhome/repo/pages/legacy.vue").then(m => m.default || m)
  },
  {
    name: "job-names",
    path: "/jobb-navn",
    component: () => import("/opt/buildhome/repo/pages/jobnames.vue").then(m => m.default || m)
  },
  {
    name: "paper_news",
    path: "/:paper/nyheter",
    component: () => import("/opt/buildhome/repo/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "paper_debate",
    path: "/:paper/debatt",
    component: () => import("/opt/buildhome/repo/pages/debate.vue").then(m => m.default || m)
  },
  {
    name: "paper_job-names",
    path: "/:paper/jobb-navn",
    component: () => import("/opt/buildhome/repo/pages/jobnames.vue").then(m => m.default || m)
  },
  {
    name: "cal",
    path: "/kalender",
    component: () => import("/opt/buildhome/repo/pages/calendarlist.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/kalender/:id",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "paper_cal",
    path: "/:paper/kalender",
    component: () => import("/opt/buildhome/repo/pages/calendarlist.vue").then(m => m.default || m)
  },
  {
    name: "paper_calendar",
    path: "/:paper/kalender/:id",
    component: () => import("/opt/buildhome/repo/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: "qa-index",
    path: "/statsradensvarer",
    component: () => import("/opt/buildhome/repo/pages/qaList.vue").then(m => m.default || m)
  },
  {
    name: "qa",
    path: "/statsradensvarer/:id",
    component: () => import("/opt/buildhome/repo/pages/qa.vue").then(m => m.default || m)
  },
  {
    name: "paper_qa-index",
    path: "/:paper/statsradensvarer",
    component: () => import("/opt/buildhome/repo/pages/qaList.vue").then(m => m.default || m)
  },
  {
    name: "paper_qa",
    path: "/:paper/statsradensvarer/:id",
    component: () => import("/opt/buildhome/repo/pages/qa.vue").then(m => m.default || m)
  },
  {
    name: "paper_raadhussvar-index",
    path: "/:paper/raadhussvar",
    component: () => import("/opt/buildhome/repo/pages/raadhussvar.vue").then(m => m.default || m)
  },
  {
    name: "rss",
    path: "/rss/:id",
    component: () => import("/opt/buildhome/repo/pages/rss.vue").then(m => m.default || m)
  },
  {
    name: "paper_rss",
    path: "/:paper/rss/:id",
    component: () => import("/opt/buildhome/repo/pages/rss.vue").then(m => m.default || m)
  },
  {
    name: "raadhussvar",
    path: "/raadhussvar/:id",
    component: () => import("/opt/buildhome/repo/pages/article.vue").then(m => m.default || m)
  },
  {
    name: "elections",
    path: "/valgomat",
    component: () => import("/opt/buildhome/repo/pages/ct/elections.vue").then(m => m.default || m)
  },
  {
    name: "election",
    path: "/valgomat/:slug",
    component: () => import("/opt/buildhome/repo/pages/ct/election.vue").then(m => m.default || m)
  },
  {
    name: "electionTest",
    path: "/valgomat/:slug/valgomat",
    component: () => import("/opt/buildhome/repo/pages/ct/electionCandidateTest.vue").then(m => m.default || m)
  },
  {
    name: "electionTestResult",
    path: "/valgomat/:slug/resultat",
    component: () => import("/opt/buildhome/repo/pages/ct/electionCandidateTestResult.vue").then(m => m.default || m)
  },
  {
    name: "electionProfile",
    path: "/valgomat/:slug/profil/:person",
    component: () => import("/opt/buildhome/repo/pages/ct/electionProfilePage.vue").then(m => m.default || m)
  },
  {
    name: "electionArticle",
    path: "/valgomat/:slug/valgresultat/:person",
    component: () => import("/opt/buildhome/repo/pages/ct/electionRobotArticle.vue").then(m => m.default || m)
  },
  {
    name: "electionBallot",
    path: "/valgomat/:slug/valgkort",
    component: () => import("/opt/buildhome/repo/pages/ct/electionBallot.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/vilkar",
    component: () => import("/opt/buildhome/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "term",
    path: "/vilkar/:termId",
    component: () => import("/opt/buildhome/repo/pages/term.vue").then(m => m.default || m)
  },
  {
    name: "paper",
    path: "/:paper",
    component: () => import("/opt/buildhome/repo/pages/paper.vue").then(m => m.default || m)
  },
  {
    name: "frontpage",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/frontpage.vue").then(m => m.default || m)
  },
  {
    name: "folkemodet_events",
    path: "/arendalsuka",
    component: () => import("/opt/buildhome/repo/pages/folkemodet/events.vue").then(m => m.default || m)
  },
  {
    name: "ads",
    path: "/annonser",
    component: () => import("/opt/buildhome/repo/pages/ads.vue").then(m => m.default || m)
  },
  {
    name: "courses",
    path: "/uddannelser",
    component: () => import("/opt/buildhome/repo/pages/courses.vue").then(m => m.default || m)
  },
  {
    name: "course",
    path: "/uddannelser/:id",
    component: () => import("/opt/buildhome/repo/pages/course.vue").then(m => m.default || m)
  },
  {
    name: "networks",
    path: "/netvaerk",
    component: () => import("/opt/buildhome/repo/pages/networks.vue").then(m => m.default || m)
  },
  {
    name: "network",
    path: "/netvaerk/:id",
    component: () => import("/opt/buildhome/repo/pages/network.vue").then(m => m.default || m)
  },
  {
    name: "yearbook",
    path: "/altinget20aar",
    component: () => import("/opt/buildhome/repo/pages/yearbook.vue").then(m => m.default || m)
  },
  {
    name: "comments-index",
    path: "/Routes.Comments",
    component: () => import("/opt/buildhome/repo/pages/comments.vue").then(m => m.default || m)
  },
  {
    name: "political-speeches-index",
    path: "/Routes.PoliticalSpeeches",
    component: () => import("/opt/buildhome/repo/pages/politicalSpeeches.vue").then(m => m.default || m)
  },
  {
    name: "yearbook",
    path: "/yearbook",
    component: () => import("/opt/buildhome/repo/pages/yearbook.vue").then(m => m.default || m)
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/misc/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/images/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/Scripts/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/scripts/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/css/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/pdffiles/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/arena",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/praktik",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/praktik/",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/hotshot",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/hotshot/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikel/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/navnenyt/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/alrow-media-udvider-styret",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/altinget-apner-3-oktober",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/altinget-etablerer-nisje-for-arbeidslivspolitikk",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/altinget-etableres-i-norge",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/altinget-henter-to-sterke-profiler-som-nisjeredaktorer",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/christina-kullmann-five-blir-nisjeredaktor-i-altinget",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/han-blir-altingets-kommersielle-direktør-i-norge",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/invitasjon",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/kontorlokaler",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/lara-rashid-blir-politisk-journalist-i-altinget",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/veslemøy-østrem-blir-sjefredaktør-for-altinget-norge",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/artikkel/annonser*",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/sponsorert/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  }
]