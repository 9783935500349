export default defineNuxtPlugin(async (_) => {
  const userStore = useUserStore()
  try {
    const jwtAccessString = useCookie<string>('AccessToken')
    const ssoCookieString = useCookie<string>('.AspNetCore.Cookies')
    const disableIPlogin = useCookie<boolean>('disableIPlogin')

    // if (userStore.isLoggedIn) {
    //   return
    // }
    if (ssoCookieString.value) {
      await userStore.loginSSO()
    } else if (!disableIPlogin.value && !jwtAccessString.value) {
      await userStore.loginIp()
    }
  } catch (error) {
    console.error('Error in auth.ts:\n', error)
  }
  userStore.loading = false
})
