export function useLegacyAutoLogin() {
  const tokenCookie = useCookie('autologin', {
    httpOnly: false,
    secure: false,
    sameSite: 'lax',
    path: '/',
  })

  const token = computed(() => {
    return tokenCookie.value
  })

  return {
    token,
  }
}
