import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faCheck as fasCheck,
  faPhone as fasPhone,
  faVolume as fasVolume,
  faVolumeSlash as fasVolumeSlash,
  faLocationDot as fasLocationDot,
  faPlus as fasPlus,
  faMinus as fasMinus,
  faBarsFilter as fasBarsFilter,
  faKey as fasKey,
  faUser as fasUser,
  faBookmark as fasBookmark,
  faArrowsToDot as fasArrowsToDot,
  faEnvelope as fasEnvelope,
  faHeart as fasHeart,
  faCreditCard as fasCreditCard,
  faCircleInfo as fasCircleInfo,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faXmarkLarge as farXmarkLarge,
  faCalendar as farCalendar,
  faMapMarkerAlt as farMapMarkerAlt,
  faCircleInfo as farCircleInfo,
  faClose as farClose,
  faPhone as farPhone,
  faEnvelope as farEnvelope,
  faInfoCircle as farInfoCircle,
  faCube as farCube,
  faArrowRight as farArrowRight,
  faArrowLeft as farArrowLeft,
  faFilePdf as farFilePdf,
  faChevronRight as farChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCircleCheck as falCircleCheck,
  faMinus as falMinus,
  faPlus as falPlus,
  faBan as falBan,
  faUser as falUser,
  faPlay as falPlay,
  faBullseyeArrow as falBullseyeArrow,
  faCalendar as falCalendar,
  faDesktop as falDesktop,
  faFileAlt as falFileAlt,
  faAd as falAd,
  faTriangleExclamation as falTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons'
import {
  faEye as fatEye,
  faBullseyeArrow as fatBullseyeArrow,
  faMobile as fatMobile,
  faNewspaper as fatNewspaper,
  faChartNetwork as fatChartNetwork,
  faPodiumStar as fatPodiumStar,
  faHandshakeAlt as fatHandshakeAlt,
} from '@fortawesome/pro-thin-svg-icons'

config.autoAddCss = false // Let Nuxt handle the CSS

library.add(
  fasCheck,
  fasPhone,
  fasVolume,
  fasVolumeSlash,
  fasLocationDot,
  fasPlus,
  fasMinus,
  fasBarsFilter,
  fasKey,
  fasUser,
  fasBookmark,
  fasArrowsToDot,
  fasEnvelope,
  fasHeart,
  fasCreditCard,
  fasCircleInfo,
  farXmarkLarge,
  farCalendar,
  farMapMarkerAlt,
  farCircleInfo,
  farClose,
  farPhone,
  farEnvelope,
  farInfoCircle,
  farCube,
  farArrowRight,
  farArrowLeft,
  farFilePdf,
  farChevronRight,
  falCircleCheck,
  falMinus,
  falPlus,
  falBan,
  falUser,
  falPlay,
  falBullseyeArrow,
  falCalendar,
  falDesktop,
  falFileAlt,
  falAd,
  falTriangleExclamation,
  fatEye,
  fatBullseyeArrow,
  fatMobile,
  fatNewspaper,
  fatChartNetwork,
  fatPodiumStar,
  fatHandshakeAlt
)

export default defineNuxtPlugin((nuxtApp) => {
  // Register the component globally
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon)
})
