// PROJECT: striphtml

// Inject $hello(msg) in Vue, context and store.

export default defineNuxtPlugin((nuxtApp) => {
  const regex = /(<([^>]+)>)/gi
  return {
    provide: {
      striphtml: (msg) => msg.replace(regex, ''),
    },
  }
})
