export default defineNuxtRouteMiddleware(async () => {
  const accessToken = useAccessToken()
  if (accessToken.token.value === undefined) {
    return
  }

  const userStore = useUserStore()

  try {
    if (!accessToken.isValid()) {
      const refreshResponse = await accessToken.refresh()
      if (refreshResponse.result === 'Refresh failed') {
        await userStore.logout()
        return
      }

      // TS is a pain sometimes
      const typedResponse = refreshResponse as {
        accessToken: string
        result: string
      }

      // Dont think the accessToken is updated at this time so the accessToken from the refresh response is used
      await userStore.loadUserFromAccessToken(typedResponse.accessToken)
    } else if (accessToken.token.value) {
      await userStore.loadUserFromAccessToken(accessToken.token.value)
    }
  } catch (error) {
    console.error('Error in 01.authRefreshCookie.global.ts:\n', error)
  }
})
