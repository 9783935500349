export default defineNuxtRouteMiddleware(async (to) => {
  const notificationsStore = useNotificationsStore()
  const userStore = useUserStore()
  const dataLayer = useDataLayer()
  const nuxtApp = useNuxtApp()
  // Handle confirmemailtoken used after confirming your email address in the trial signup flow. Only run on initial client load.
  const isInitialClientLoad =
    import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered
  if (to.query?.confirmemailtoken && isInitialClientLoad) {
    try {
      // TODO this will break when or if the PATCH CALL do not return the user object.
      const user = await userStore.confirmEmail({
        token: to.query.confirmemailtoken.toString(),
      })
      await dataLayer.trial.activateUser()
      await userStore.loadUserFromAutologin({
        autologin: user.autoLogin,
      })
      await notificationsStore.add({
        translationKey: 'EmailConfirmed',
        type: 'success',
      })
      if (to.query.receiptPapers) {
        try {
          userStore.sendAccessGrantedReceiptEmail({
            userId: user.id,
            paperIds: to.query.receiptPapers.toString(),
          })
        } catch (e) {
          const error = new Error('Could not send access granted receipt', {
            cause: e,
          })
          console.error(error)
          if (import.meta.client) {
            nuxtApp.$sentry.captureException(error)
          }
          notificationsStore.add({
            translationKey: 'user.errors.CouldNotSendAccesGrantedReceipt',
            type: 'error',
          })
        }
      } else {
        const errorMsg =
          'Missing receiptPapers query parameter, so no access granted receipt was sent'

        console.error(errorMsg)
        if (import.meta.client) {
          nuxtApp.$sentry.captureException(new Error(errorMsg))
        }
      }
    } catch (e) {
      const error = new Error('Error confirming email', {
        cause: e,
      })
      console.error(error)
      if (import.meta.client) {
        nuxtApp.$sentry.captureException(error)
      }
      return await notificationsStore.add({
        translationKey: 'user.errors.EmailTokenDoesNotExist',
        type: 'error',
      })
    }
  }
  const ssoResult = to.query?.SSOResult?.toString()
  // Handle SSOResult used after logging in with SSO
  if (ssoResult) {
    // First time login
    if (ssoResult === 'Success') {
      await notificationsStore.add({
        translationKey: 'WelcomeTextSSO',
        type: 'success',
        showSubscribeButton: true,
      })
      return
    } else if (ssoResult === 'SuccessSSOUserAlreadyExists') {
      // Logged in before, no need to show a modal
      return
    } else if (ssoResult === 'EmailNotSSODomainClient') {
      // Logged in with wrong SSO Domain
      await notificationsStore.add({
        translationKey: 'sso.NotSSOEmail',
        type: 'error',
      })
      return
    }
    await notificationsStore.add({
      translationKey: 'unknownError',
      type: 'error',
    })
  }
})
