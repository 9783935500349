import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig().public

  const head = injectHead()

  nuxtApp.vueApp.use(
    createGtm({
      ...runtimeConfig.gtm,
      vueRouter: useRouter(),
      trackOnNextTick: true,
      vueRouterAdditionalEventData: async () => {
        // Wait for page title to be resolved before tracking
        await new Promise<void>((resolve) => {
          nuxtApp.hooks.hookOnce('page:finish', async () => {
            if (import.meta.client) {
              await head.resolveTags()
            }
            resolve()
          })
        })

        return {}
      },
    })
  )
})
