import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import strapi_CICqBBANNd from "/opt/buildhome/repo/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import adProfit_client_8n1VL2CWA3 from "/opt/buildhome/repo/plugins/adProfit.client.ts";
import api_GFfDXud5Cr from "/opt/buildhome/repo/plugins/api.ts";
import auth_client_4lHQFpoKNF from "/opt/buildhome/repo/plugins/auth.client.ts";
import fontawesome_cn2c4tOOHz from "/opt/buildhome/repo/plugins/fontawesome.ts";
import i18n_VfGcjrvSkj from "/opt/buildhome/repo/plugins/i18n.ts";
import intlFormatter_0S2cI1Scom from "/opt/buildhome/repo/plugins/intlFormatter.js";
import sentry_client_shVUlIjFLk from "/opt/buildhome/repo/plugins/sentry.client.ts";
import striphtml_JR8NK2XY1J from "/opt/buildhome/repo/plugins/striphtml.js";
import vue_gtm_yUJQMSAo2j from "/opt/buildhome/repo/plugins/vue-gtm.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  strapi_CICqBBANNd,
  adProfit_client_8n1VL2CWA3,
  api_GFfDXud5Cr,
  auth_client_4lHQFpoKNF,
  fontawesome_cn2c4tOOHz,
  i18n_VfGcjrvSkj,
  intlFormatter_0S2cI1Scom,
  sentry_client_shVUlIjFLk,
  striphtml_JR8NK2XY1J,
  vue_gtm_yUJQMSAo2j
]