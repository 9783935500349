import { SiteIdentifier } from '~/typesManual/config/site'

export const useSiteConfig = () => {
  const config = useRuntimeConfig()
  return config.public.site
}

export const isSite = (...siteIdentifiers: SiteIdentifier[]) => {
  const siteConfig = useSiteConfig()
  return siteIdentifiers.includes(siteConfig.identifier)
}
