import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45refresh_45cookie_45global from "/opt/buildhome/repo/middleware/01.authRefreshCookie.global.ts";
import _02_45auth_45auto_45login_45cookie_45global from "/opt/buildhome/repo/middleware/02.authAutoLoginCookie.global.ts";
import current_45paper_45global from "/opt/buildhome/repo/middleware/currentPaper.global.ts";
import inject_45alrow_45user_45global from "/opt/buildhome/repo/middleware/injectAlrowUser.global.ts";
import kill_45broken_45redirects_45global from "/opt/buildhome/repo/middleware/killBrokenRedirects.global.ts";
import query_45param_45handler_45global from "/opt/buildhome/repo/middleware/queryParamHandler.global.ts";
import statistics_45global from "/opt/buildhome/repo/middleware/statistics.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45refresh_45cookie_45global,
  _02_45auth_45auto_45login_45cookie_45global,
  current_45paper_45global,
  inject_45alrow_45user_45global,
  kill_45broken_45redirects_45global,
  query_45param_45handler_45global,
  statistics_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/buildhome/repo/middleware/auth.ts")
}