import { decodeJwt } from 'jose'
import type { JwtPayload } from '~/typesManual/jwt'
import type { MinimalUser } from '~/api/account'
import type { OldUser } from '~/api/user'

import { FetchError } from 'ofetch'
import type { ArticleUserDTO } from '~/typesAuto/apicore/v1'

export type LoginResponse = {
  status: 'success' | 'error'
  message: string
}

export type TempUser = {
  // statemanagement
  loadedAccessToken?: string

  // basic
  userId: number
  userGuid?: string
  name: string
  email: string
  workEmailConfirmed: boolean
  hasActiveSubscription: boolean
  hasActiveNonTrialSubscription: boolean
  papers: number[]
  isAdmin: boolean

  // organistation
  clientId?: number
  jobPlace?: string

  // mit altinget
  educationId?: number | null
  userBranchId?: number | null
  userTitleId?: number | null
  countryId?: number | null
  birthyear?: string
  zipcode?: string
  contactPhone: string

  // extra
  avatar?: string
  readingList: ArticleUserDTO[]

  // deprecated
  loginType: 'IP' | 'newuser' | 'SSO'
  legacyAutoToken: string
  contactEmail?: string
}

function mergeToTempUser(
  newUser: MinimalUser,
  oldUser: OldUser,
  readingList: ArticleUserDTO[]
): TempUser {
  const newTempUser: TempUser = {
    loadedAccessToken: undefined,
    userId: newUser.id ?? 0,
    name: newUser.name ?? '',
    email: newUser.workEmail,
    workEmailConfirmed: oldUser.workEmailConfirmed
      ? Boolean(oldUser.workEmailConfirmed)
      : false,
    legacyAutoToken: newUser.autoLogin ?? '',
    readingList: readingList,
    avatar: newUser.avatar ?? undefined,
    isAdmin: oldUser.isAdmin,
    loginType: oldUser.loginType,
    birthyear: newUser.birthyear?.toString() ?? '',
    contactPhone: newUser.contactPhone ?? '',
    contactEmail: newUser.contactEmail ?? '',
    jobPlace: newUser.client?.jobPlace,
    zipcode: newUser.zipcode ?? '',
    hasActiveSubscription: oldUser.hasActiveSubscription,
    hasActiveNonTrialSubscription: oldUser.hasActiveNonTrialSubscription,
    papers: oldUser.papers,
    clientId: newUser.client?.id ?? oldUser.clientId,

    educationId: newUser.educationId,
    userBranchId: newUser.userBranchId,
    userTitleId: newUser.userTitleId,
    countryId: newUser.countryId,
  }

  return newTempUser
}

export const useUserStore = defineStore('user', () => {
  const nuxtApp = useNuxtApp()

  const autologinCookie = useCookie('autologin', {
    path: '/',
    sameSite: 'lax',
    maxAge: 60 * 60 * 24 * 365,
  })

  const ssoCookieString = useCookie<string>('.AspNetCore.Cookies', {
    readonly: true,
  })

  /*
  // State
  */
  const user = ref<TempUser | null>(null)
  const loading = ref(true)

  /*
  // Getters
  */
  const loadedAccessToken = computed(() => user.value?.loadedAccessToken)

  const isLoggedIn = computed(() => Boolean(user.value))

  const hasSubscription = (paperId: number) => {
    return user.value?.papers.includes(paperId)
  }

  const hasClient = computed(() => user.value?.clientId ?? 0 > 0)

  const hasName = computed(() => !!user.value?.name)

  const hasImage = computed(() => {
    return user.value?.avatar && user.value.avatar !== 'avatar_grey.svg'
  })

  const hasConfirmedWorkEmail = computed(() => !!user.value?.workEmailConfirmed)

  const emailBelongsToAlrow = computed(() => {
    return /@(mm\.dk|(altinget\.(dk|se|no)))$/g.test(user.value?.email ?? '')
  })

  const id = computed(() => user.value?.userId)

  /*
  // Actions
  */
  const loadUserFromAccessToken = async (accessToken: string) => {
    if (
      !accessToken ||
      (user.value && user.value.loadedAccessToken === accessToken)
    ) {
      return
    }

    const userPayload = decodeJwt<JwtPayload>(accessToken)
    if (!userPayload.userId) {
      console.error('payload:', userPayload)
      console.error('payload userId not found login failed')
      return
    }

    const userData = await nuxtApp.$api.account.getUser(userPayload.userId)
    const readingList = await nuxtApp.$api.user.getReadingList(
      userPayload.userId
    )

    user.value = {
      // statemanagement
      loadedAccessToken: accessToken,
      // basic
      userId: userPayload.userId,
      userGuid: userPayload.userGuid,
      name: userData.name ?? userPayload['name'],
      email: userPayload['name'],
      workEmailConfirmed: userPayload.isEmailConfirmed,
      hasActiveSubscription: userPayload.hasActiveSubscription,
      hasActiveNonTrialSubscription: userPayload.hasActiveNonTrialSubscription,
      papers: userPayload.paperIds ?? [],
      isAdmin: userPayload.isAdmin,

      // organistation
      clientId: userData.client?.id,
      jobPlace: userData.client?.jobPlace,

      // mit altinget
      educationId: userData.educationId,
      userBranchId: userData.userBranchId,
      userTitleId: userData.userTitleId,
      countryId: userData.countryId,

      birthyear: userData.birthyear?.toString() ?? '',
      zipcode: userData.zipcode ?? '',
      contactPhone: userData.contactPhone ?? '',

      // extra
      avatar: userData.avatar ?? undefined,
      readingList: readingList,

      // deprecated
      legacyAutoToken: userData.autoLogin, // used by legacy pages decisionChain iframe.
      loginType: 'newuser',
    } as TempUser

    return user.value
  }

  const loadUserFromAutologin = async ({
    autologin,
  }: {
    autologin: string
  }) => {
    let oldUser = {} as OldUser
    if (ssoCookieString.value) {
      oldUser = await nuxtApp.$api.user.ssoLogin()
    } else {
      oldUser = await nuxtApp.$api.user.autologin(autologin)
    }

    let userData = {} as MinimalUser
    let readingList: ArticleUserDTO[] = []
    if (oldUser.userId > 0 && oldUser.autologin) {
      userData = await nuxtApp.$api.account.getUser(oldUser.userId)
      readingList = await nuxtApp.$api.user.getReadingList(oldUser.userId)
    } else {
      // Its an IP login. Grab the name from oldUser and overwrite workEmailConfirmed
      userData.name = oldUser.name
      userData.workEmailConfirmed = 1
      userData.autoLogin = oldUser.autologin
      userData.client = {
        id: oldUser.clientId,
        jobPlace: oldUser.jobPlace ?? '',
        zipcode: '',
      }
    }

    user.value = mergeToTempUser(userData, oldUser, readingList)
    return user.value
  }

  const confirmEmail = async ({ token }: { token: string }) => {
    return await nuxtApp.$api.user.confirmEmail(token)
  }

  const refreshUser = async () => {
    const accessToken = useAccessToken()

    if (accessToken.token.value) {
      const refreshResponse = await accessToken.refresh()
      if (refreshResponse.result === 'Refresh failed') {
        await logout()
        return
      }

      // TS is a pain sometimes
      const typedResponse = refreshResponse as {
        accessToken: string
        result: string
      }

      await loadUserFromAccessToken(typedResponse.accessToken)
    } else {
      try {
        const oldUser = await nuxtApp.$api.user.autologin(
          user.value?.legacyAutoToken ?? ''
        )
        await setUserCookies(oldUser.autologin ?? '')

        const userData = await nuxtApp.$api.account.getUser(oldUser.userId)
        const readingList = await nuxtApp.$api.user.getReadingList(
          oldUser.userId
        )

        user.value = mergeToTempUser(userData, oldUser, readingList)
      } catch (error) {
        console.error('LoginUser failed with error:', error)
      }
    }
  }

  const loginUserJwt = async (username: string, password: string) => {
    const response = await $fetch<LoginResponse>('/api/auth/sign-in', {
      method: 'POST',
      body: {
        username,
        password,
      },
    })

    refreshCookie('AccessToken')

    if (response.status === 'success') {
      const accessToken = useAccessToken()

      if (accessToken.token.value) {
        await loadUserFromAccessToken(accessToken.token.value)
      }
    }
  }

  const loginUser = async (username: string, password: string) => {
    await $fetch<LoginResponse>('/api/auth/old/sign-in', {
      method: 'POST',
      body: {
        username,
        password,
      },
    })

    refreshCookie('autologin')

    const autoLogin = useLegacyAutoLogin()
    if (autoLogin.token.value) {
      loadUserFromAutologin({ autologin: autoLogin.token.value })
    }
  }

  const loginIp = async () => {
    try {
      await $fetch<LoginResponse>('/api/auth/old/sign-in-ip', {
        method: 'POST',
      })

      refreshCookie('autologin')

      const autoLogin = useLegacyAutoLogin()
      if (autoLogin.token.value) {
        loadUserFromAutologin({ autologin: autoLogin.token.value })
      }
    } catch (error) {
      console.error('LoginIp failed with error:', error)
      return null
    }
  }

  const loginSSO = async () => {
    try {
      await $fetch<LoginResponse>('/api/auth/old/sign-in-sso', {
        method: 'POST',
      })

      refreshCookie('autologin')

      const autoLogin = useLegacyAutoLogin()
      if (autoLogin.token.value) {
        loadUserFromAutologin({ autologin: autoLogin.token.value })
      }
    } catch (error) {
      console.error('LoginSSO failed with error:', error)
      return null
    }
  }

  const sendAccessGrantedReceiptEmail = async ({
    userId,
    paperIds,
  }: {
    userId: string
    paperIds: string
  }) => {
    return await nuxtApp.$api.user.sendAccessGrantedReceiptEmail(
      parseInt(userId),
      paperIds
    )
  }

  const resendActivationMail = async () => {
    if (!user.value?.email) {
      return
    }

    try {
      await nuxtApp.$api.user.resendActivationMail(user.value.email)
      return { message: 'EmailSent', type: 'success' }
    } catch (error) {
      if (error instanceof FetchError && error.response) {
        return {
          message: error.response?._data as string,
          type: 'error',
        }
      } else {
        console.error('Error in resendActivationMail:', error)
      }
    }
  }

  const setUserCookies = (cookie: string) => {
    try {
      autologinCookie.value = cookie
      refreshCookie('autologin')

      loading.value = false
    } catch (e) {
      console.error('Got an error in setUserCookies. The error was:\n', e)
      throw e
    }
  }

  const logout = async () => {
    const loginType = user.value?.loginType

    await $fetch('/api/auth/sign-out', {
      method: 'GET',
    })

    user.value = null
    useCookie<boolean>('disableIPlogin').value = true

    if (loginType === 'SSO') {
      const config = useRuntimeConfig()
      await navigateTo(
        `https://${config.public.site.apicoreurl}/ssoindex/signout`,
        { external: true }
      )
    }
  }

  return {
    user,
    loading,
    loadedAccessToken,
    isLoggedIn,
    hasSubscription,
    hasClient,
    hasName,
    hasImage,
    hasConfirmedWorkEmail,
    emailBelongsToAlrow,
    id,
    loadUserFromAccessToken,
    loadUserFromAutologin,
    refreshUser,
    loginUserJwt,
    loginUser,
    loginIp,
    loginSSO,
    confirmEmail,
    sendAccessGrantedReceiptEmail,
    resendActivationMail,
    setUserCookies,
    logout,
  }
})
